.crm-filters__body-action {
  display: flex;
  justify-content: flex-end;
}

.crm-filter {
  display: flex;
  align-items: flex-end;

  & > * {
    width: 100%;
  }
}
