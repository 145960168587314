.crm-list__body .crm-table-body {
  background: transparent;
}

.crm-list .crm-page-header {
  background: transparent;
}


.crm-list__body .crm-table-header {
  background: transparent;
}
