.ant-modal {

  &-content {
    box-shadow: @shadow-modal;
    border-radius: 0;
  }

  &-header {
    border-bottom-color: @c-grey-light-01;
    padding: 20px;
  }

  &-title {
    font-size: 20px;
    color: @c-grey-03;
  }

  &-body {
    padding: 30px 30px 25px 30px;
  }

  &-footer {
    border-top: none;
    padding: 15px 30px 30px 30px;
  }
}

.ant-modal-confirm-btns {
  text-align: end;
  margin-top: 24px;

  color: #8c8c8c;

  & button {
    margin-left: 16px;
  }
}
