.crm-page-header {
  background: transparent;

  &__body-content-tags {
    // Styles for transaction detail reclaim tag icon
    .ant-tag .anticon.anticon-undo {
      color: @c-red-01;
    }
  }
}
.ant-btn.page-header-action {
  text-transform: unset;

  &.ant-btn {
    min-width: unset;
  }

  &:hover {
    background: transparent;
  }
}

.ant-dropdown-trigger#action-buttons_more_dropdown {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 8px;

  span {
    margin-left: 0;
  }
}
