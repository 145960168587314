.crm-tabbed {
  .ant-tabs {
    background: transparent;
  }

  .ant-tabs-nav {
    background: transparent;
  }

  .crm-list {
    .crm-page-header {
      padding: 0;
    }

    .crm-list__body {
      .crm-table-header {
        padding: 0;
      }

      .crm-table-body {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
