.crm-detail .crm-general-composite .crm-detail-body {
  background: transparent;

  .crm-list__body .crm-table-body {
    padding: 0;
  }

  .crm-list__body .crm-table-header {
    padding: 0;
    margin-bottom: 24px;
  }

  .crm-page-header {
    padding: 24px 0;
  }
}
