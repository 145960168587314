.ant-radio-group.crm-form-radio-button-group {
  width: 100%;
  display: flex;

  label.crm-form-radio-button-option.ant-radio-button-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
  }
}
