.ant-btn {
  text-transform: uppercase;
  font-size: 14px;
  min-width: 120px;

  &.ant-btn-text.ant-btn-sm {
    &:hover {
      background: transparent;
    }
  }

  &.ant-btn-icon-only {
    min-width: unset;
  }

  &-sm {
    font-size: 14px;
  }

  &-primary {
    background-color: @c-green-02;
    color: @c-white;
    border-color: @c-green-02;
  }

  &-danger {
    background-color: @c-red-01;
    color: @c-white;
    border-color: @c-red-01;
  }

  &-link {
    min-width: auto;
  }
}
