.crm-layout-main {
  background: #FAFAFA;
}

.crm-layout-content-wrapper {
  background: transparent;
}

.ant-layout-sider-light {
  background: transparent;
}

.crm-layout-sider .ant-layout-sider-children {
  background-color: @c-white;
}

.crm-layout-sider {
  .ant-menu.ant-menu-inline.ant-menu-sub {
    background: #FAFAFA;
  }
}
