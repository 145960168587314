.ant-table-thead > tr > th {
  color: @c-grey-light-05;
  background: #ebebeb;
}

.ant-table-tbody tr > td {
  color: @c-grey-light-05;
}

.ant-table-tbody tr > td:first-child {
  color: #444444;
  font-weight: 500;
}
